import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import ImgMurah from "../../assets/images/feature-tile-icon-01.svg";
import ImgBerkualitas from "../../assets/images/feature-tile-icon-02.svg";
import ImgCepat from "../../assets/images/feature-tile-icon-03.svg";
import ImgNyaman from "../../assets/images/feature-tile-icon-04.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src={ImgMurah}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Murah</h4>
                  <p className="m-0 text-sm">
                    Tapi bukan murahan, kami akan memberikan pilihan service
                    terbaik yang paling Optimum dan Ekonomis, dengan
                    mengedapankan skala prioritas terhadap setiap perbaikan dan
                    pergantian parts. Sehingga biaya yang dikeluarkan tidak
                    membengkak.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src={ImgBerkualitas}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Berkualitas</h4>
                  <p className="m-0 text-sm">
                    Parts, Olie, Bahan Pendukung yang kita pilih sudah terbukti
                    berkualitas baik dan sudah di coba di aplikasikan pada unit
                    kami sendiri. Dengan pemilihan barang berkualitas menjamin
                    mobil anda lebih enak untuk di kendarai dan ber umur lebih
                    panjang.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src={ImgCepat}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Cepat</h4>
                  <p className="m-0 text-sm">
                    Dengan menggunakan sarana yang kita punya dan stock parts
                    yang kami miliki, kami berkomitmen untuk memberikan
                    pelayanan cepat pada setiap perbaikan kendaraan anda.
                    Lakukan booking terlebih dahulu pada kontak yang tertera.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src={ImgNyaman}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Nyaman</h4>
                  <p className="m-0 text-sm">
                    Suasana bengkel kami berada di area sisi pegunungan dimana
                    akan nyaman apabila menunggu perbaikan kendaraan anda selama
                    proses service dan perbaikan.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
