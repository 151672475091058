import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Chakra Motor Bandung - Bengkel Spesialis Ford Bandung</title>
        <meta name="description" content="Bengkel Spesialis Ford bandung" />
      </Helmet>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />
      <Testimonial topDivider />
      <div style={{ textAlign: "center" }}>
        <h2>Booking & Home Service</h2>
        <h3>082121484077 - 0811226636</h3>
      </div>
      {/* <Cta split /> */}
    </>
  );
};

export default Home;
