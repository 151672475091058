import React, { useState } from "react";

const Banner = ({ ...props }) => {
  const [bannerOpen] = useState(false);

  return (
    <>
      {bannerOpen && (
        <div {...props} className="banner">
          <div className="banner-inner text-xxs"></div>
        </div>
      )}
    </>
  );
};

export default Banner;
