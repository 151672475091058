import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const handleSendWa = () => {
    const text = `Data Booking Service Chakra Motor Bandung.

Nama :
Alamat :
No HP :
Tanggal Reservasi :
Jam Reservasi :
Merk Mobil :
Type :
Keluhan :`;

    const chat = encodeURIComponent(text);

    window.open(`https://wa.me/6282121484077?text=${chat}`);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              CHAKRA MOTOR <span className="text-color-primary">BANDUNG</span>
            </h1>
            <div className="container-xs">
              <h1
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
                style={{ fontSize: "20px" }}
              >
                Bengkel Spesialis Ford di Bandung.
              </h1>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="https://tokopedia.link/chakramotor"
                  >
                    Online Store
                  </Button>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    onClick={() => handleSendWa()}
                  >
                    Booking Service
                  </Button>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="https://goo.gl/maps/971Dd6vLmfn21U6E6"
                  >
                    Lokasi Bengkel
                  </Button>
                  <Button
                    tag="a"
                    color="light"
                    wideMobile
                    href="https://g.co/kgs/ekJhyA"
                  >
                    Review Kami di Google
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
